@use "../../../../styles/variables" as *;

.emptyPlan {
  margin: 72px 0 48px 0;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: $greyish-brown;
  margin: 24px 0;
}

.modalContent {
  width: 100%;
}

:global {
  .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    color: $greyish-brown;
  }
}

.selectDropdown {
  :global {
    .ant-select-item-option-disabled {
      background-color: $background;
    }
  }
}
