@use "../../../../styles/mixins";

.tabCardContainer {
  @include mixins.tab-container;

  :global {
    .ant-row.ant-form-item {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 24px;
    }

    .ant-form-item-label {
      align-self: start;
    }

    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: bold;
      min-width: 0;
    }
  }
}