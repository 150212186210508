@use "../../../../styles/variables" as *;

.promotionDetailContainer {
  :global {
    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: bold;
      min-width: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .formSections {
    width: 100%;

    .subtitle {
      font-size: 18px;
      font-weight: 700;
      color: $greyish-brown;
    }
  }
}

.buttons {
  :global {
    .ant-btn-default[disabled] {
      background-color: $white;
    }
  }
}
